<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="txt_box px-6">
				<h1 class="tit_page">납부 (신용카드)</h1>
				<p class="txt_body1 mb-8">
					고객님이 납부하실 신용카드 정보를 등록해 주세요.
					<br />납부 금액은 총
					<span class="primary--text"> {{ recpInfos.amount | comma }} 원</span
					>입니다.
				</p>
			</div>

			<CardPayForm ref="card" :hidePaymentDate="true" :hideBizNo="true" />
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					{{ recpInfos.amount | comma }} 원 납부 진행
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn>
			</div>
			<CustomRecpDialog ref="recpPop" />
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { cardTransfer } from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객납부
			},
			mthd: '02' // 카드이체 - 기타결제
		}
	},
	mounted() {
		this.recpInfos = this.$store.getters['recp/recpInfos']
		if (!this.recpInfos.mthd) this.recpInfos.mthd = this.mthd
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async chkReturn(prm, rtn, tcObj) {
			this.$log('rtn:', JSON.stringify(rtn))
			if (rtn.TYPE === 'S') {
				// store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				await this.$alert({
					message:
						'일회성 납부금 결제가 완료되었습니다."계속 진행하기"를 선택하여 전자 계약서 서명을 완료해 주세요.',
					okText: '계속 진행하기'
				})

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				fetchWebOrderInfoById(mobOrderNo).then(res => {
					let webParams = res.resultObject
					let order = JSON.parse(webParams.orderInfoJson)
					order = Object.assign(order, {
						recp: {
							recpParams: this.$store.getters['recp/recpParams'],
							recpInfos: this.$store.getters['recp/recpInfos'],
							isCustSelfPay: true,
							recpCardResult: tcObj,
							payRecp: this.$store.getters['recp/payRecp']
						}
					})
					webParams.step = '06' // 전자계약
					webParams.statusCode = '108' // 서명입력
					webParams.orderInfoJson = JSON.stringify(order)

					postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
						this.$log('postRes:---- ', JSON.stringify(postRes))
						// 전자계약
						this.$router.push({
							name: 'ec-cust-pass',
							query: { userKey: this.$route.query.userKey }
						})
					})
				})
			} else {
				const options = {
					title: '다음과 같은 사유로 납부가 거부됐습니다.',
					guide: '다른 카드로 납부하시기 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '납부방법 재선택',
					cancelText: ''
				}

				const popRes = await this.$refs.recpPop.open(options)

				if (popRes) {
					// 납부방법 재선택
					this.onResetPayMethod()
				}
			}
		},
		async goRecp() {
			const formData = this.$refs.card.onSubmit()
			if (!formData) return
			this.$log('card formData:', formData)

			let payRecp = {
				AF_OWNER_NM: formData.custName,
				AF_BANK_CD_NM: formData.bankNm,
				AF_CARD_NO: formData.accountNo
			}

			this.$store.dispatch('recp/setPayRecp', payRecp)

			const options = {
				title: '다음 카드를 통해 결제를 요청합니다.',
				contents: {
					납부방법: `${formData.bankNm} 
					${this.$options.filters.star(formData.accountNo, 6)}`,
					납부금액: `${this.$options.filters.comma(this.recpInfos.amount)}원`
				},
				okText: '결제',
				cancelText: '납부방법 재선택'
			}

			const popRes = await this.$refs.recpPop.open(options)

			if (popRes) {
				let prm = {
					zwebRecp: '00',
					payMthd: this.mthd,
					userId: this.recpInfos.userId,
					cardn: formData.accountNo,
					avDat: `20${formData.avDatYear}${formData.avDatMonth}`,
					okamt: this.recpInfos.amount,
					kunnr: this.recpInfos.kunnr,
					name: formData.custName,
					depositor: formData.custName,
					restry: formData.custType,
					ssn: formData.ssn,
					bankc: formData.bankCd,
					bankNm: formData.bankNm,
					phone: this.$store.getters['verify/custPhone'],
					mobOrderNo: this.$store.state['verify'].mobOrderNo,
					insMon: formData.insMon
				}

				const res = await cardTransfer(prm)
				const rtn = res.resultObject.data.E_RETURN
				await this.chkReturn(prm, rtn, res.resultObject.data.TC_CARD[0])
			} else {
				// 납부방법 재선택
				this.onResetPayMethod()
			}
		}
	}
}
</script>
